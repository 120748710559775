jQuery.noConflict();
(function( $ ) {

	$(document).ready(function(){

		/* slick */
		$('.v-fp-slider__slider').slick({
			slide: 'div',
			dots: true,
			arrows: true,
			fade: true,
			accessibility: true,
			autoplay: true,
			autoplaySpeed: 12000,
			draggable: true,
			pauseOnFocus: false,
			pauseOnHover: false,
			swipe: true,
			touchMove: true,
			slidesToShow: 1
		});

	});

})(jQuery);